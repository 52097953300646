import { ReactElement } from "react";

const Container = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => {
  return (
    <div className="pl-5 pr-5 min-w-screen min-h-screen bg-gradient-to-br from-purple-mountain-500 via-purple-mountain-600 to-curious-blue-500 leading-normal text-base tracking-normal text-white w-screen h-screen flex justify-center items-center">
      {children}
    </div>
  );
};

export default Container;
