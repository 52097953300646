import { ReactElement } from "react";

const VerticalStack = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => {
  return <div className="flex flex-col justify-between items-center">{children}</div>;
};

export default VerticalStack;
