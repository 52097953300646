import React from "react";
import "./App.css";
import { VerticalStack } from "./components/ui";
import Container from "./components/ui/Container";

const App = () => {
  return (
    <Container>
      <VerticalStack>
        <div>
          <img
            className="w-full h-full max-w-md"
            src="https://aleksieff.eu/logo-white.svg"
            alt="aleksieff.com"
          />
        </div>
        <h1 className="text-3xl uppercase mt-20 tracking-wider text-center">
          <p className="font-bold mb-5">Stay tuned</p>
          <p className="font-normal">The new website will be launched soon.</p>
        </h1>
      </VerticalStack>
    </Container>
  );
};

export default App;
